<template>
    <div class="container">
        <div class="navbar">
            <h2 style="">JANAPAY.KZ</h2>
            <div style="display: flex; width: 150px; justify-content: center; align-items: center; border: 2px solid #324fc4; border-radius: 30px; padding: 10px; gap: 10px">
                <span class="material-symbols-outlined" style="color: #324fc4"> account_circle </span>
                <router-link class="align-center" to="/admin" style="text-decoration: none; color: #324fc4"> Admin</router-link>
            </div>
        </div>
    </div>
</template>
<script></script>

<style scoped>
.container {
    position: relative;
    max-width: 100vw;
    width: 100%;
    height: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("@/assets/images/back-janapay.png");
}
.navbar {
    width: 50%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h2 {
    color: #324fc4;
    margin-bottom: 50px;
    font-size: 45px;
}

@media (max-width: 1000px) {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    h2 {
        font-size: 40px;
    }
}
</style>
