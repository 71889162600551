<template>
    <div>
        <a-config-provider :locale="locale" size="default">
            <router-view />
        </a-config-provider>
        <div class="loading-mask" v-if="isLoading">
            <a-spin style="margin: auto" size="large" />
        </div>
    </div>
</template>
<script>
import enUS from "ant-design-vue/es/locale/en_US";
import kkKZ from "ant-design-vue/es/locale/kk_KZ";
import ru_RU from "ant-design-vue/es/locale/ru_RU";
export default {
    data() {
        return {
            // locale: kk,
            theme: {
                token: {
                    colorPrimary: "#3ab06c",
                    fontSize: 12,
                    wireframe: false,
                },
            },
        }
    },
    computed: {
        isLoading() {
            return this.$store?.isLoading || false
        },
        locale() {
            switch (this.$i18n.locale) {
                case "en":
                    return enUS
                case "kk":
                    return kkKZ
                case "ru":
                    return ru_RU
            }
            return enUS
        },
    },
    mounted() {
        this.watchChangeSize()
        window.onresize = () => {
            this.watchChangeSize()
        }
    },
    methods: {
        watchChangeSize() {
            let offsetWid = document.documentElement.clientWidth
            if (offsetWid >= 900 && this.$store.$state.isBigWin == false) {
                // console.log('Big window');
                this.$store.$state.isBigWin = true
            } else if (offsetWid < 900 && this.$store.$state.isBigWin == true) {
                console.log("sml window")
                this.$store.$state.isBigWin = false
            }
            offsetWid = null
        },
    },
}
</script>
<style lang="scss">
.loading-mask {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
}

.page-header {
    padding: 10px 0;
}

.filter-area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    padding: 10px;
    box-sizing: border-box;
    overflow-x: auto;
    background: #f5f5f5;

    .filter-box {
        display: flex;
        align-items: center;
        gap: 5px;

        .input {
            min-width: 100px;
        }
        p {
            @media (max-width: 748px) {
                min-width: 120px;
                // width:100%
            }
        }
    }
}

</style>
