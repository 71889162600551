import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import { useAppStore } from './store'
import i18n from './locales/index'
import "./assets/style/global.css"
import VueNumber from "vue-number-animation";
import dayjs from 'dayjs';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
    easing: 'ease',  // 动画方式
    speed: 500,  // 递增进度条的速度
    showSpinner: true, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})
router.beforeEach((to, from , next) => {
    // 每次切换页面时，调用进度条
    NProgress.start();

    // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
    next();
});
router.afterEach(() => {
    // 在即将进入新的页面组件前，关闭掉进度条
    NProgress.done()
})


// pritn
import print from 'print-js'
import 'print-js/dist/print.css';

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(VueNumber)
app.config.globalProperties.$store = useAppStore()
app.use(i18n).use(router).use(Antd).mount('#app')


app.config.globalProperties.$dateFormat = "DD/MM/YYYY";
app.config.globalProperties.$timeFormat = timeFormat;
app.config.globalProperties.$getHourMinute = getHourMinute;
app.config.globalProperties.$getFileName = getFileName;
app.config.globalProperties.$toTimeStamp = toTimeStamp;
app.config.globalProperties.$dayjs = dayjs;
// app.config.globalProperties.$getFileUrl = getFileUrl;
app.config.globalProperties.$publicPath = process.env.BASE_URL
app.config.globalProperties.$firstUpperCase = firstUpperCase
app.config.globalProperties.$componentSize = 'small'
app.config.globalProperties.$print = print;

function timeFormat(t, hm) {
	if (!t) {
		return ''
	}
	if (typeof t != 'number') {
		return t
	}
	let d = new Date(t);
	let day = d.getDate();
	let m = d.getMonth() + 1;
	let y = d.getFullYear();
	if (day.toString().length == 1) {
		day = "0" + day.toString()
	}
	if (m.toString().length == 1) {
		m = "0" + m.toString()
	}
	let h = d.getHours();
	let mn = d.getMinutes();
	if (h.toString().length < 2) {
		h = "0" + h;
	}
	if (mn.toString().length < 2) {
		mn = "0" + mn;
	}
	if (hm) {
		return `${day}/${m}/${y} ${h}:${mn}`
	}
	return `${day}.${m}.${y}`
}

function getHourMinute(t, s) {
	if (typeof t != 'number') {
		return t
	}
	if (s) {
		t = t / 1000;
	}
	let d = new Date(t * 1000);
	let h = d.getHours();
	let mn = d.getMinutes();
	if (h.toString().length < 2) {
		h = "0" + h;
	}
	if (mn.toString().length < 2) {
		mn = "0" + mn;
	}
	return `${h}:${mn}`
}

function getFileName(url) {
	if (url && typeof url === "string") {
		if (url.indexOf('/') == -1) {
			return url
		}
		return url.split('/')[url.split('/').length - 1]
	} else {
		return ""
	}
}

function toTimeStamp(time) {
	let e = time || 0
	if (e) {
		return (dayjs(e).valueOf() / 1000).toFixed(0) * 1;
	} else {
		return e
	}
}

// function getFileUrl(url) {
// 	if (url.indexOf('http') == 0) {
// 		return url
// 	}
// 	return config.baseURL + url
// }

function firstUpperCase(word) {
	if (!word || typeof word != 'string') {
		return ''
	}
	return word.charAt(0).toUpperCase() +
		word.slice(1)
}



